// script.js

$(document).ready(function() {


    $(document).on("click", "#try", function () {
        alert('yes')

    });

    // $(document).on('click','.pq-ad-title',function(){
    //     alert('yes')
    //     var key = $(this).data('key');
    //     console.log(key)
    //     $('.pq-details-' + key).slideToggle();
    //     $(this).find('.fa').toggleClass('active inactive');
    // })

    // $('.ad-title-text').click(function() {
    //     var key = $(this).data('key');
    //     var content = $('.pq-details-' + key);

       
    //     $('.pq-accordion-details').not(content).slideUp();
    //     $('.fa-chevron-up').removeClass('active');
    //     $('.fa-chevron-down').addClass('active');

        
    //     content.slideToggle();
    //     $(this).find('.fa-chevron-down, .fa-chevron-up').toggleClass('active');
    // });
});

   

