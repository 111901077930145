$(document).ready(function() {

    var invoice_table = $('#invoice_table').DataTable({
        responsive: true,
        processing: true,
        serverSide: true,
        scrollX: true,
        orderCellsTop: true,
        searchDelay: 500,
        // dom: '<"row"<"col-md-12"<"row"<"col-md-6"B><"col-md-6"f> > ><"col-md-12"rt> <"col-md-12"<"row"<"col-md-5"i><"col-md-7"p>>> >',
        buttons: {
            buttons: [{
                extend: 'excel',
                className: 'btn'
            },
            {
                extend: 'print',
                className: 'btn'
            }
            ]
        },
         
        ajax: {
            "type": 'POST',
            "url": '/billing/ajax_fetchinv',
            "data": function (d) {
                d._token = $('meta[name="csrf-token"]').attr('content');
            }
        },
        columns: [
            {
                data: "DT_RowIndex",
                name: "DT_RowIndex",
                className: "text-center align-middle",

                orderable: false,
                searchable: false,
            },
            {
                data: 'month',
                name: 'month',
                className: "text-center align-middle",

            },
            {
                data: 'status',
                name: 'status',
                className: "text-center align-middle",
                render: function (data) {
                    var icon_paid = '<i class="fas fa-check-double fa-2x" style="color: #47cd56;" title="Paid"></i>';
                    var icon_unpaid = '<i class="far fa-times-circle fa-2x" style="color: #cb2a2a;" title="Not Paid"></i>';
                    var status = data === 1 ? icon_paid : data === 2 ? icon_unpaid : "";
            
                    return status;
                },

            },
            {
                data: 'bill_type',
                name: 'bill_type',
                className: "text-center align-middle",
                render: function (data) {
                    // var icon_bill = '<i class="fas fa-receipt fa-2x" title="Bill"></i>';
                    // var icon_invoice = '<i class="fas fa-file-invoice fa-2x" title="Invoice"></i>';
                    var icon = data === 'bill' ? 'Bill' : 'Invoice';
            
                    return icon;
                },
            },
            {
                data: 'inv_id',
                orderable: false,
                className: "text-center align-middle",
                render: function (data, type, row, meta) {
                    var type_bill = row.bill_type === 'bill' ? 'bill' : 'invoice';

                    return '<button type="button" id="viewBill" data-id="' + data + '" class="btn btn-sm btn-primary px-3 viewBill m-1" data-toggle="tooltip" value="'+type_bill+'" data-placement="bottom" title="View"><i class="fas fa-eye"></i></button>'+
                     '<a href="/billing/viewpdf/'+type_bill+'/'+ data +'" type="button" id="print" data-id="' + data + '" class="btn btn-sm btn-warning px-3 print m-1" data-toggle="tooltip" value="'+type_bill+'" data-placement="bottom" title="Print"><i class="fas fa-print"></i></a>';
                    
                    
                },
            },
            
            
        ],

        order: [
            [0, 'desc']
        ],

        "oLanguage": {
            "oPaginate": {
                "sPrevious": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>',
                "sNext": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>'
            },
            "sInfo": "Display  _PAGE_ of _PAGES_",
            "sSearch": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>',
            "sSearchPlaceholder": "Search...",
            "sLengthMenu": "Lists :  _MENU_",
        },
        "stripeClasses": [],
        "lengthMenu": [7, 10, 20, 50],
        "pageLength": 10,

    });

    $(document).on('click','#pills-bill-tab', function(){
        $('#pill_bill_table').show();
        $('#bills_table').columns.adjust();
    });

    $(document).on('click','#viewBill',function(){
        var id_inv = $(this).data('id');
        // alert(id_inv)
        $.ajax({
           type:'post',
           url:'/billing/ajax_invoice',
           data:{
               _token: $('meta[name="csrf-token"]').attr('content'),
               id: id_inv,
               page_name: $('#viewBill').val(),
           },
           success:function(results){
            // console.log(results)
                if(results.status == true){
                    // $('#page_name').val(results.page_name);
                    window.location.href = '/billing/view/'+results.id;
        
                }
            }
        })
              
    });


    var plan_table = $('#plan_table').DataTable({
        responsive: true,
        processing: true,
        serverSide: true,
        scrollX: true,
        orderCellsTop: true,
        searchDelay: 500,
        searchable:false,
        // dom: '<"row"<"col-md-5"i>>',
         
        ajax: {
            "type": 'POST',
            "url": '/billing/ajax_fetchplan',
            "data": function (d) {
                d._token = $('meta[name="csrf-token"]').attr('content');
            }
        },
        columns: [
            {
                data: "DT_RowIndex",
                name: "DT_RowIndex",
                className: "text-center align-middle",
                width: 20,

                orderable: false,
                searchable: false,
            },
            {
                data: 'plan_name',
                name: 'plan_name',
                className: "text-center align-middle",
                width: 100,

                orderable: false,
                searchable: false,

            },
            {
                data: 'amount',
                name: 'amount',
                className: "text-center align-middle",
                width: 30,

                orderable: false,
                searchable: false,
            },
            {
                data: 'total',
                name: 'total',
                className: "text-center align-middle",
                width:20,
                orderable: false,
                searchable: false,
            }
            
            
        ],
    });

    $(document).on('click','#credit_card',function(){
        $('#show_credit_card').show();
    })

    $(document).on('click','#online_banking',function(){
        alert('Redirect to online banking');
        $('#show_credit_card').hide();

    })

    var yourplan_table = $('#your_plan_table').DataTable({
        "paging": false, 
        "ordering": false, 
        "info": false , 
        "searching": false,

        ajax: {
            "type": 'POST',
            "url": '/billing/ajax_fetchplan',
            "data": function (d) {
                d._token = $('meta[name="csrf-token"]').attr('content'),
                d.id = $('.user_id').data('id')
            }
        },
        columns: [
            {
                data: 'plan_name',
                name: 'plan_name',
                className: "text-center align-middle",
                // width: 100,

                orderable: false,
                searchable: false,

            },
            {
                data: 'unit',
                name: 'unit',
                className: "text-center align-middle",
                // width: 30,

                orderable: false,
                searchable: false,
            },
            {
                data: 'cost',
                name: 'cost',
                className: "text-center align-middle",
                // width:20,
                orderable: false,
                searchable: false,
            },
            {
                data: 'status',
                name: 'status',
                className: "text-center align-middle",
                // width:20,
                orderable: false,
                searchable: false,
            },
            {
                data: '',
                orderable: false,
                className: "text-center align-middle",
                render: function (data, type, row, meta) {

                    return '<button type="button" id="view" data-id="' + data + '" class="btn btn-sm btn-primary px-3 m-1" data-toggle="tooltip" data-placement="bottom" title="Lihat"><i class="fas fa-eye"></i></button>';
                    
                    
                },
            },
            
            
        ],
    });

    var faq_lists_table = $('#faq_lists_table').DataTable({
        responsive: true,
        processing: true,
        serverSide: true,
        // scrollX: true,
        // orderCellsTop: true,
        // searchDelay: 500,
        // searchable:false,
        // dom: '<"row"<"col-md-5"i>>',
         
        ajax: {
            "type": 'POST',
            "url": '/faq/ajax_fetchfaq',
            "data": function (d) {
                d._token = $('meta[name="csrf-token"]').attr('content');
            }
        },
        columns: [
            {
                data: "DT_RowIndex",
                name: "DT_RowIndex",
                className: "text-center align-middle",

            },
            {
                data: 'questions',
                name: 'questions',
                className: "text-center align-middle",

            },
            {
                data: 'category',
                name: 'category',
                className: "text-center align-middle",

            },
            {
                data: 'faq_id',
                orderable: false,
                className: "text-center align-middle",
                render: function (data, type, row, meta) {

                    return '<button type="button" id="edit" data-id="' + data + '" class="btn btn-sm btn-warning px-3 m-1" data-toggle="tooltip" data-placement="bottom" title="Edit"><i class="fas fa-edit"></i></button>'+
                    '<button type="button" id="delete_faq" data-id="' + data + '" class="btn btn-sm btn-danger px-3 m-1" data-toggle="tooltip" data-placement="bottom" title="Delete"><i class="fas fa-trash"></i></button>';
                    
                    
                },
            },
            
        ],
        order: [
            [0, 'desc']
        ],

        "oLanguage": {
            "oPaginate": {
                "sPrevious": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>',
                "sNext": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>'
            },
            "sInfo": "Paparan _PAGE_ daripada _PAGES_",
            "sSearch": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>',
            "sSearchPlaceholder": "Carian...",
            "sLengthMenu": "Senarai :  _MENU_",
        },
        "stripeClasses": [],
        "lengthMenu": [7, 10, 20, 50],
        "pageLength": 10,
    });

    $(document).on('click','#edit', function(){

        $.ajax({
            type: 'POST',
            url: '/faq/getfaqid',
            data:{
                _token : $('meta[name="csrf-token"]').attr('content'),
                faq_id : $(this).data('id')
            },success:function(result){
                if(result.status == true){
                    $('#update_faq').modal('show');
                    $('#quest').val(result.faqs.question);
                    $('#answer').val(result.faqs.answer);
                    $('#faq_category').val(result.faqs.category_id);
                    $('#faq_id').val(result.faqs.id);
                }
            }
        })
    })

    $(document).on('click','#update', function(){
        $.ajax({
            type: 'POST',
            url: '/faq/update_faq',
            data:{
                _token : $('meta[name="csrf-token"]').attr('content'),
                faq_id : $('#faq_id').val(),
                question : $('#quest').val(),
                answer : $('#answer').val(),
                category : $('#faq_category').val(),
            },success:function(res){
                if(res.status == true){
                    Swal.fire({
                        icon: 'success',
                        title:'Updated Successfully'}).then(function(){
                        $('#update_faq').modal('hide');

                    })
                }else{
                    Swal.fire({
                        icon: 'error',
                        title:'Fail Update'})
                }
            }
        })
    })

    $(document).on('click','#delete_faq', function(){

        Swal.fire({
            showCancelButton: true,
            cancelButtonColor: '#3B71CA',
            cancelButtonText: 'Cancel',
            confirmButtonColor: '#198754',
            confirmButtonText: 'Yes',
            title: 'Your are about to delete the faq. Sure want to continue?',
            icon: 'question',
            padding: '2em'
        }).then((value)=>{
            if (value.isConfirmed) {

                    $.ajax({
                    type: 'DELETE',
                    url: '/faq/delete_faq',
                    data:{
                        _token : $('meta[name="csrf-token"]').attr('content'),
                        faq_id : $(this).data('id'),
                    },success:function(res){
                        if(res.status == true){
                            Swal.fire({
                                icon: 'success',
                                title:'Deleted Successfully'}).then(function(){
                                window.location.reload()

                            })
                        }else{
                            Swal.fire({
                                icon: 'error',
                                title:'Fail Delete'})
                        }
                    }
                })
            }
        })
        
    })
  
    var billing_table = $("#billing_table").DataTable({
        responsive: true,
        processing: true,
        serverSide: true,
        scrollX: true,
        orderCellsTop: true,
        searchDelay: 500,
        lengthChange: false,
        // dom: '<"row"<"col-md-12"<"row"<"col-md-6"B><"col-md-6"f> > ><"col-md-12"rt> <"col-md-12"<"row"<"col-md-5"i><"col-md-7"p>>> >',
        buttons: {
            buttons: [
                {
                    extend: "excel",
                    className: "btn",
                },
                {
                    extend: "print",
                    className: "btn",
                },
            ],
        },

        ajax: {
            type: "POST",
            url: "/billing/bill/list/ajax",
            data: function (d) {
                d._token = $('meta[name="csrf-token"]').attr("content");
            },
        },
        columns: [
            {
                data: "DT_RowIndex",
                name: "DT_RowIndex",
                className: "text-center align-middle",

                orderable: false,
                searchable: false,
            },
            {
                data: "created_by",
                name: "created_by",
                className: "text-center align-middle",
            },
            {
                data: "email",
                name: "email",
                className: "text-center align-middle",
            }, 
            {
                data: "latest_status",
                name: "status",
                className: "text-center align-middle",
                render: function (data, type, full, meta) {
                    if (data === 0) {
                        return '<span class="badge badge-danger">Unpaid</span>';
                    } else if (data === 1) {
                        return '<span class="badge badge-success">Paid</span>';
                    } else {
                        return ''; 
                    }
                }
            },
            
            {
                data: "user_id",
                orderable: false,
                className: "text-center align-middle",
                render: function (data, type, row, meta) {
                    // return
                    //  '<button type="button" id="viewBill" data-id="' + data + '" class="btn btn-sm btn-primary px-3 viewBill m-1" data-toggle="tooltip" value="'+type_bill+'" data-placement="bottom" title="Lihat"><i class="fas fa-eye"></i></button>'+
                    return (
                        '<a href="/billing/bill/detail/' +
                        data +
                        '"data-id="' +
                        data +
                        '" data-toggle="tooltip"  data-placement="bottom" title="Bill History" style="color: blue; text-decoration: underline;">Bill Details</a>'
                    );
                },
            },
        ],

        order: [[0, "desc"]],

        oLanguage: {
            oPaginate: {
                sPrevious:
                    '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>',
                sNext: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>',
            },
            sInfo: "Display _PAGE_ of _PAGES_",
            sSearch:
                '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>',
            sSearchPlaceholder: "Search...",
            sLengthMenu: "List :  _MENU_",
        },
        stripeClasses: [],
        lengthMenu: [7, 10, 20, 50],
        pageLength: 10,
    });

   


  
    
    
});