
$(document).ready(function () {

var helpdesk_table = $("#helpdesk_table").DataTable({
    responsive: true,
    processing: true,
    serverSide: true,
    scrollX: true,
    orderCellsTop: true,
    searchDelay: 500,
    lengthChange: false,
    // dom: '<"row"<"col-md-12"<"row"<"col-md-6"B><"col-md-6"f> > ><"col-md-12"rt> <"col-md-12"<"row"<"col-md-5"i><"col-md-7"p>>> >',
    buttons: {
        buttons: [
            {
                extend: "excel",
                className: "btn",
            },
            {
                extend: "print",
                className: "btn",
            },
        ],
    },

    ajax: {
        type: "POST",
        url: "/Helpdesk/ticket/list/ajax",
        data: function (d) {
            d._token = $('meta[name="csrf-token"]').attr("content");
        },
    },
    columns: [
        {
            data: "DT_RowIndex",
            name: "DT_RowIndex",
            className: "text-center align-middle",

            orderable: false,
            searchable: false,
        },
        {
            data: "requester_name",
            name: "requester_name",
            className: "text-left",
        },
        {
            data: "department",
            name: "department",
            className: "text-left",
        },
        {
            data: "requester_email",
            name: "requester_email",
            className: "text-left",
        },
        {
            data: "status",
            name: "status",
            className: "text-center align-middle",
            render: function (data, type, row) {
                var status = row.status;
        
                if (status === 'Process') {
                    return '<span class="badge badge-pill badge-primary">Process</span>';
                } else if (status === 'Open') {
                    return '<span class="badge badge-pill badge-success">Open</span>';
                } else if (status === 'In Progress') {
                    return '<span class="badge badge-pill badge-warning">In Progress</span>';
                } else if (status === 'Resolved') {
                    return '<span class="badge badge-pill badge-success">Resolved</span>';
                } else { 
                    return '';
                }
            }
        },  
        {
            data: "priority",
            name: "priority",
            className: "text-left",
            render: function(data, type, row){
                var priority = row.priority;
                if(priority === 'High')
                {
                return '<i class="fas fa-bookmark" style="color: #ffdd00;"></i><span> High</span>';
                }else if(priority === 'Medium')
                {
                return '<i class="fas fa-bookmark" style="color: #11ff00;"></i><span> Medium</span>'; 
                }else if(priority === 'Urgent')
                {
                return '<i class="fas fa-bookmark" style="color: #ff0000;"></i><span> Urgent</span>'; 
                }else { 
                return '<i class="fas fa-bookmark" style="color: #00a6ff;"></i><span> Low</span>';
                }
            }
        }, 
        {
            data: "existing_status",
            name: "existing_status",
            className: "text-center align-middle",
            render: function (data, type, full, meta) {
                if (data) {
                    return '<i class="fas fa-check-circle" style="color: #00ff11;"></i>';
                } else {
                    return '<i class="fas fa-times-circle" style="color: #ff0000;"></i>';
                }
            },
        }, 
        {
            data: "id",
            orderable: false,
            className: "text-center align-middle",
            render: function (data, type, row, meta) {
                // return
                //  '<button type="button" id="viewBill" data-id="' + data + '" class="btn btn-sm btn-primary px-3 viewBill m-1" data-toggle="tooltip" value="'+type_bill+'" data-placement="bottom" title="Lihat"><i class="fas fa-eye"></i></button>'+
                return (
                    '<a href="/Helpdesk/edit/' +
                    data +
                    '"data-id="' +
                    data +
                    '" data-toggle="tooltip"  data-placement="bottom" title="Support Ticket Details" style="color: blue; text-decoration: underline;">View</a>'
                );
            },
        },
    ],

    order: [[0, "desc"]],

    oLanguage: {
        oPaginate: {
            sPrevious:
                '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>',
            sNext: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>',
        },
        sInfo: "Paparan _PAGE_ daripada _PAGES_",
        sSearch:
            '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>',
        sSearchPlaceholder: "Search...",
        sLengthMenu: "List :  _MENU_",
    },
    stripeClasses: [],
    lengthMenu: [7, 10, 20, 50],
    pageLength: 10,
});

var helpdesk_user_table = $("#helpdesk_user_table").DataTable({
    responsive: true,
    processing: true,
    serverSide: true,
    scrollX: true,
    orderCellsTop: true,
    searchDelay: 500,
    lengthChange: false,
    // dom: '<"row"<"col-md-12"<"row"<"col-md-6"B><"col-md-6"f> > ><"col-md-12"rt> <"col-md-12"<"row"<"col-md-5"i><"col-md-7"p>>> >',
    buttons: {
        buttons: [
            {
                extend: "excel",
                className: "btn",
            },
            {
                extend: "print",
                className: "btn",
            },
        ],
    },

    ajax: {
        type: "POST",
        url: "/Helpdesk/ticket/list/user/ajax",
        data: function (d) {
            d._token = $('meta[name="csrf-token"]').attr("content");
        },
    },
    columns: [
        {
            data: "DT_RowIndex",
            name: "DT_RowIndex",
            className: "text-center align-middle",

            orderable: false,
            searchable: false,
        },
        {
            data: "subject",
            name: "subject",
            className: "text-left",
        },
        {
            data: "status",
            name: "status",
            className: "text-center align-middle",
            render: function (data, type, row) {
                var status = row.status;
        
                if (status === 'Process') {
                    return '<span class="badge badge-pill badge-primary">Process</span>';
                } else if (status === 'Open') {
                    return '<span class="badge badge-pill badge-success">Open</span>';
                } else if (status === 'In Progress') {
                    return '<span class="badge badge-pill badge-warning">In Progress</span>';
                } else if (status === 'Resolved') {
                    return '<span class="badge badge-pill badge-success">Resolved</span>';
                } else { 
                    return '';
                }
            }
        },  
        {
            data: "id",
            orderable: false,
            className: "text-center align-middle",
            render: function (data, type, row, meta) {
                // return
                //  '<button type="button" id="viewBill" data-id="' + data + '" class="btn btn-sm btn-primary px-3 viewBill m-1" data-toggle="tooltip" value="'+type_bill+'" data-placement="bottom" title="Lihat"><i class="fas fa-eye"></i></button>'+
                return (
                    '<a href="/Helpdesk/edit/' +
                    data +
                    '"data-id="' +
                    data +
                    '" data-toggle="tooltip"  data-placement="bottom" title="Support Ticket Details" style="color: blue; text-decoration: underline;">View</a>'
                );
            },
        },
    ],

    order: [[0, "desc"]],

    oLanguage: {
        oPaginate: {
            sPrevious:
                '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>',
            sNext: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>',
        },
        sInfo: "Display _PAGE_ of _PAGES_",
        sSearch:
            '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>',
        sSearchPlaceholder: "Search...",
        sLengthMenu: "List :  _MENU_",
    },
    stripeClasses: [],
    lengthMenu: [7, 10, 20, 50],
    pageLength: 10,
});

// $('#support').summernote({
//     placeholder: 'Start typing...',
//     tabsize: 2,
//     height: 250,
//     toolbar: [
//     ['style', ['style']],
//     ['font', ['bold', 'underline', 'italic', 'clear']],
//     ['fontname', ['fontname']],
//     ['color', ['color']],
//     ['para', ['ul', 'ol', 'paragraph']],
//     ['table', ['table']],
//     ['insert', ['link', 'picture']],
//     ['view', []],
//     ]
// });


 
if ($("#card_helpdesk_table").length > 0) {
   
    $.ajax({
        url: "/Helpdesk/list", 
        type: "GET",
        dataType: "json",
        success: function (response) {
          
            const cardList = $("#card_helpdesk_table");
            cardList.empty();
        
            response.forEach(data => {
                const cardClass = data.priority === 'Low' ? '#0066ff' :
                    data.priority === 'Medium' ? '#00cc00' :
                    data.priority === 'High' ? '#ffcc00' :
                    data.priority === 'Urgent' ? '#ff0000' : '';
        
                const cardId = data.id;
        
                let cardHtml = '<a href="/Helpdesk/edit/' + cardId + '" data-id="' + cardId + '" class="card ' + cardClass + ' data-toggle="modal" data-target="#detailModal' + cardId + '">' +
                '<div class="card-body">' + 
                '<p class="card-title"><i class="fas fa-bookmark" style="color: ' + cardClass + ';"></i> ' + data.department + '<small><br>'+ 'Requester Email: ' + data.requester_email + '<br>' +  'Existing Customer:'+ ' ' +(data.existing_status ? '<i class="fas fa-check-circle" style="color: #00ff11;"></i>' : '<i class="fas fa-times-circle" style="color: #ff0000;"></i>') + '</small></p>' +
                '</div>' +
                '</a>';
            
        
                cardList.append(cardHtml);
            });
        },
        
        
        
        error: function (xhr, status, error) {
            console.error("Error fetching data:", error);
        },
    });
}console.log('Script is being executed.');



if ($("#card_helpdeskUser_table").length > 0) {
    
    $.ajax({
        url: "/Helpdesk/user_support/list", 
        type: "GET",
        dataType: "json",
        success: function (response) {
            
            const cardList = $("#card_helpdeskUser_table");
            cardList.empty();
        
            response.forEach(data => {
                const cardClass = data.status === 'Process' ? '#0066ff' :
                    data.status === 'Open' ? '#00cc00' :
                    data.status === 'In Progress' ? '#ffcc00' :
                    data.status === 'Resolved' ? '#ff0000' : '';
        
                const cardId = data.id;

                let statusBadge = '';
                if (data.status === 'Process') {
                    statusBadge = '<span class="badge badge-pill badge-primary">Process</span>';
                } else if (data.status === 'Open') {
                    statusBadge = '<span class="badge badge-pill badge-success">Open</span>';
                } else if (data.status === 'In Progress') {
                    statusBadge = '<span class="badge badge-pill badge-warning">In Progress</span>';
                } else if (data.status === 'Resolved') {
                    statusBadge = '<span class="badge badge-pill badge-success">Resolved</span>';
                }
        
        
                let cardHtml = '<a href="/Helpdesk/edit/' + cardId + '" data-id="' + cardId + '" class="card ' + cardClass + ' data-toggle="modal" data-target="#detailModal' + cardId + '">' +
                '<div class="card-body">' + 
                '<div class="row">'+
                '<div class="col-3" style="display: flex; justify-content: center; align-items: center;">'+
                ' <i class="fas fa-ticket-alt" style="color: #1e3050; font-size: 24pt;"></i>'+
                '</div>'+
                '<div class="col-9">'+
                '<p class="card-title">'+'Subject : '+ ' ' + data.subject + '<small><br>'+ 'Current Status: ' + statusBadge  + '</small></p>' +
                '</div>' +
                '</div>'+
                '</div>'+
                '</a>';

             
                cardList.append(cardHtml);
            });
        },
         
        error: function (xhr, status, error) {
            console.error("Error fetching data:", error);
        },
    });
}console.log('Script is being executed.');


$(document).on("click", "#deleteTicket", function () {
    var ticketId = $(this).data("id");

    console.log(ticketId)

    Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
    }).then((result) => {
        if (result.isConfirmed) {
            $.ajax({
                type: "POST",
                url: "/Helpdesk/ticket/delete",
                data: {
                    _token: $('meta[name="csrf-token"]').attr("content"),
                    id: ticketId,
                },
                success: function () {
                        Swal.fire({
                            title: "Success!",
                            text: "Your Ticket has been deleted",
                            icon: "success",
                            padding: "2em",
                        });
                        window.location.href = "/Helpdesk/ticket";
                    },
            });
        }
    });
});



// $("#composeButton").click(function () {
//     $("#composeForm").toggle();
// });




// $(document).on("click","#composeEmailButton",function(){
//     $("#composeEmailForm").slideDown();
//     console.log("Compose Email button clicked");
 
// });


// $(document).on("click","#closeComposeEmailButton",function(){
//     $("#composeEmailForm").slideUp();
  
// });










// $(document).on("click", "#update_status", function () {
//     var updateId = $(this).data("id");

//     Swal.fire({
//         showCancelButton: true,
//         cancelButtonColor: "#3B71CA",
//         cancelButtonText: "Cancel",
//         confirmButtonColor: "#198754",
//         confirmButtonText: "Yes, Update Status",
//         title: "Update Status",
//         html: "Are you sure you want to update the status for the case?",
//         icon: "question",
//         padding: "2em",
//     }).then((result) => {
//         if (result.value == true) {
//             let loading = Swal.fire({
//                 html: "Please Wait...",
//                 allowEscapeKey: false,
//                 allowOutsideClick: false,
//                 didOpen: () => {
//                     Swal.showLoading();
//                 },
//             });

//             $.ajax({
//                 type: "POST",
//                 url: "/Helpdesk/support/update" + updateId,
//                 data: {
//                     _token: $('meta[name="csrf-token"]').attr("content"),
//                     id: updateId,
//                     status_support: $('[name="status_support"]').val(),
//                     description: $('[name="description"]').val(), 
//                 },
//                 success: function (res) {
//                     if (res.message) {
//                         if (res.success == true) {
//                             Swal.fire({
//                                 title: res.message,
//                                 icon: "success",
//                                 title: "Success!",
//                                 text: "Status Successfully Updated",
//                                 padding: "2em",
//                             }).then((result) => {
//                                 if (result.isConfirmed) {
//                                     window.location.reload();
//                                 }
//                             });
//                         } else {
//                             Swal.fire({
//                                 title: res.message,
//                                 icon: "error",
//                                 padding: "2em",
//                             }).then((result) => {
//                                 if (result.isConfirmed) {
//                                     window.location.href = "/dashboard";
//                                 }
//                             });
//                         }
//                     } else {
//                         Swal.fire({
//                             title: "An error occurred",
//                             text: "Something went wrong while storing the purchase plan.",
//                             icon: "error",
//                             padding: "2em",
//                         });
//                     }
//                 },

//                 error: function (xhr, status, error) {
//                     // Handle errors here
//                     console.log(error);
//                     Swal.fire({
//                         title: "An error occurred",
//                         text: "Something went wrong while updating the status.",
//                         icon: "error",
//                         padding: "2em",
//                     });
//                 },
//             });
 
//         }
//     });
// });





});