
window.$ = window.jQuery = $;

import 'datatables.net-bs4';


import 'bootstrap';
import AOS from 'aos';
import 'aos/dist/aos.css';

 
 
// Initialize AOS
AOS.init();

require('./billing_js/billing');
require('./blog/list');
require('./pricing/pricing');
require('./register');
require('./landing-page');
require('./ticket/Helpdesk');
require('./faq/faq');

$('[data-toggle="dropdown"').dropdown();





