$(document).ready(function () {
    $(document).on('change','input[name="email"].register',function(){

        $.ajax({
            url: 'https://disposable.debounce.io/?email=' + $(this).val(),
            method: "GET",
            dataType: "json",
            success:function(res){
                if (res.disposable == 'true') {
                    $('input[name="email"].register').addClass('is-invalid');
                    $('.is-invalid-password').text('This is disposable email. Please use valid email.');
                    $('.register-btn').prop('disabled', true);
                } else {
                    $('input[name="email"].register').removeClass('is-invalid');
                    $('.is-invalid-password').text('');
                    $('.register-btn').prop('disabled', false);
                }
            },
        });
    });
});